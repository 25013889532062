<template>
  <div>
    <!-- 顶部 -->
    <el-row class="app">
      <el-col :span="16">
        <div class="page-container">
          <div class="examInfoHead">
            <span class="examState">
              {{ examInfo.examStatus | examStatus }}
            </span>
            <span class="examName ellipsis">{{ examInfo.examName }}</span>
            <el-button
              v-throttle
              size="medium"
              type="primary"
              @click="seeExamDetailed"
              >查看成绩明细</el-button
            >
          </div>
          <span class="span_inner">{{ examInfo.examTime }}</span>
          <span class="span_inner" style="margin-top: 8px"
            >科目:{{ examInfo.examSubject }}</span
          >
          <div style="margin-top: -21px; margin-left: 170px; color: #909399">
            <span>考点数量: {{ examinationNum }}</span>
            <span v-if="examination != 0" style="margin-left: 50px"
              >考试时长: {{ examination }}min</span
            >
          </div>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="select-content">
          <el-form :inline="true" :model="form">
            <el-form-item label="科目" class="formItemBoxStyle">
              <el-select
                v-model="form.subject"
                @change="conditionChangeQuery"
                style="width: 180px"
              >
                <el-option
                  v-for="(v, i) in this.subjectTypeConditionList"
                  :key="i"
                  :label="v | subjectFilter"
                  :value="v"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="学校" class="formItemBoxStyle">
              <el-select
                v-model="form.school"
                @change="conditionChangeQuery"
                style="width: 180px"
              >
                <el-option label="全部" :value="0"></el-option>
                <el-option
                  v-for="(v, i) in this.schoolConditionList"
                  :key="i"
                  :value="v.sysOrgSchoolId"
                  :label="v.sysOrgSchoolName"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
      </el-col>
    </el-row>
    <!-- 内容部分第一行 -->
    <el-row :gutter="25" style="margin-top: 25px">
      <el-col :span="7">
        <div class="grid-content firstBlock">
          <div
            ref="chart"
            id="chart"
            style="position: relative; height: 400px; width: 100%"
          ></div>
          <!-- <div style="font-size:15px;margin-top:-30px;margin-left:80px">
            <div style="position:absolute;width:300px;">
              <span>报考人数:{{thatForm.examineeCount}}人</span>
              <span style="position:absolute;left:140px;">已考人数:{{thatForm.hasExamExamineeCount}}人</span>
            </div>
            <div style="position:absolute;width:300px;margin-top:40px;">
              <span>取消考试:{{thatForm.cancelledExamCount}}人</span>
              <span style="position:absolute;left:140px;">参加补考:{{thatForm.makeupCount}}人</span>
            </div>
          </div>-->
          <div class="firstTitle">
            <span class="titleText">报考人数</span>
            <span class="titleText">已考人数</span>
            <span class="titleText">取消考试</span>
            <span class="titleText">参加补考</span>
          </div>
          <div>
            <span class="firstContent" style="margin-left: 45px">
              <span class="blueBlock"></span>
              <span class="contentText">{{ thatForm.examineeCount }}</span>
              <span class="fixedContent">人</span>
            </span>
            <span class="firstContent" style="margin-left: 150px">
              <span class="blueBlock"></span>
              <span class="contentText">
                {{ thatForm.hasExamExamineeCount }}
              </span>
              <span class="fixedContent">人</span>
            </span>
            <span class="firstContent" style="margin-left: 258px">
              <span class="blueBlock"></span>
              <span class="contentText">{{ thatForm.cancelledExamCount }}</span>
              <span class="fixedContent">人</span>
            </span>
            <span class="firstContent" style="margin-left: 363px">
              <span class="blueBlock"></span>
              <span class="contentText">{{ thatForm.makeupCount }}</span>
              <span class="fixedContent">人</span>
            </span>
          </div>
        </div>
      </el-col>

      <el-col :span="7" style="height: 450px">
        <div class="grid-content secondBlock">
          <!-- <div class="roundList">
            <div
              class="roundOne"
              v-for="(v,i) in 7"
              :key="i"
              :style="'width:'+(320-30*i)+'px;height:'+(320-30*i)+'px;top:calc(20% + '+(15*i)+'px);left:calc(20% + '+(15*i)+'px);'"
            ></div>
          </div>-->
          <div
            ref="distrChart"
            id="distrChart"
            style="position: relative; width: 100%; height: 400px"
          ></div>
          <span
            style="position: absolute; left: 52%; top: 22px; cursor: pointer"
            @click="scoreCounting"
            >查看详情></span
          >
          <!-- <div class="textOne">
            <span>单位：人</span>
          </div>-->
          <div class="textTwo">
            <span>满分：{{ thatForm.fullScore | pointSupply }}分</span>
            <span style="margin-left: 20px"
              >及格：≥{{ thatForm.passScore | pointSupply }}分</span
            >
          </div>
        </div>
      </el-col>

      <el-col :span="10">
        <div class="grid-content thirdBlock" style="position: relative">
          <div
            style="
              margin-left: 7%;
              margin-top: 20px;
              margin-bottom: 15px;
              font-size: 18px;
            "
          >
            各分数段人数统计
          </div>
          <span
            style="
              position: absolute;
              right: 50px;
              top: 22px;
              cursor: pointer;
              z-index: 9;
            "
            @click="peopleCounting"
            >查看详情></span
          >
          <!-- 数据表格 -->
          <!-- <div class="that">
            <div class="that1">
              <span class="thatText">
                低分人数(0~{{ lowSectionData[0].lowThreshold | pointSupply }}分)
              </span>
              <span class="thatNum">{{ lowSectionData[0].lowNumber }}</span>
            </div>
            <div class="that2" style="width: 16%">
              <span class="thatText">低分率</span>
              <span class="thatNum">{{ lowSectionData[0].lowPrecent }}</span>
            </div>
            <div class="that3">
              <span class="thatText">
                及格人数(≥{{ lowSectionData[0].passThreshold | pointSupply }}分)
              </span>
              <span class="thatNum">{{ lowSectionData[0].passNumber }}</span>
            </div>
            <div class="that4" style="width: 16%">
              <span class="thatText">及格率</span>
              <span class="thatNum">{{ lowSectionData[0].passPrecent }}</span>
            </div>
            <div class="that5">
              <span class="thatText">
                优分人数(≥{{
                  highSectionData[0].fineThreshold | pointSupply
                }}分)
              </span>
              <span class="thatNum">{{ highSectionData[0].fineNumber }}</span>
            </div>
            <div class="that6" style="width: 16%">
              <span class="thatText">优分率</span>
              <span class="thatNum">{{ highSectionData[0].finePrecent }}</span>
            </div>
            <div class="that7">
              <span class="thatText">
                高分人数(≥{{
                  highSectionData[0].highThreshold | pointSupply
                }}分)
              </span>
              <span class="thatNum">{{ highSectionData[0].highNumber }}</span>
            </div>
            <div class="that8" style="width: 16%">
              <span class="thatText">高分率</span>
              <span class="thatNum">{{ highSectionData[0].highPrecent }}</span>
            </div>
          </div> -->
          <div id="scorePeriodEchartsID"></div>
          <!-- 成绩异议统计 -->
          <div style="font-size: 18px; position: absolute; left: 7%; top: 60%">
            成绩异议统计
          </div>

          <div class="thatSecond">
            <div class="thatSecond1">
              <span class="thatText">成绩异议人数</span>
              <span class="thatNum">
                {{ markObjectionData[0].confusionCount }}
              </span>
            </div>
            <div class="thatSecond2" style="width: 16%">
              <span class="thatText">待审批异议</span>
              <span class="thatNum">
                {{ markObjectionData[0].waitApproveConfusionCount }}
              </span>
            </div>
            <div class="thatSecond3">
              <span class="thatText">待重新阅卷</span>
              <span class="thatNum">
                {{ markObjectionData[0].waitMarkConfusionCount }}
              </span>
            </div>
            <div class="thatSecond4" style="width: 16%">
              <span class="thatText" style="margin-top: 18px">
                <el-button
                  v-throttle
                  type="primary"
                  size="medium"
                  @click="lookObjectionView"
                  >查看异议</el-button
                >
              </span>
            </div>
          </div>
          <!--  background:'#f5f7fa', -->
          <!-- <el-table
            :data="markObjectionData"
            border
            style="width: 86%;font-size:14px;margin-left:7%"
            :header-cell-style="{ 
            'text-align':'center'}"
            :cell-style="{'text-align':'center'}"
          >
            <el-table-column prop="confusionCount" label="成绩异议人数" width="135"></el-table-column>
            <el-table-column prop="waitApproveConfusionCount" label="待审批异议" width="135"></el-table-column>
            <el-table-column prop="waitMarkConfusionCount" label="待重新阅卷"></el-table-column>
            <el-table-column prop="highPrecent" label="操作" width="110">
              <el-button v-throttle  type="primary" size="small" @click="lookObjectionView">查看</el-button>
            </el-table-column>
          </el-table>-->
        </div>
      </el-col>
    </el-row>

    <!-- 内容部分第二行 -->
    <el-row :gutter="25" style="margin-top: 25px; margin-bottom: 20px">
      <el-col :span="9">
        <div class="fourthBlock">
          <div
            ref="scoreChart"
            id="scoreChart"
            style="height: 100%; width: 100%"
          ></div>
        </div>
        <!-- <div ref="studentChart" class="fourthBlock" id="studentChart" style="width: 100%;"></div> -->
      </el-col>

      <el-col :span="8">
        <div class="page-content sixthBlock" style="width: 92%;padding-right: 20px;">
          <div
            class="sixthTitle"
            style="
              margin-bottom: 20px;
              display: flex;
              background-color: #fff;
              justify-content: space-between;
            "
          >
            <span>试题分析</span>
            <div
              style="
                font-weight: 400;
                font-size: 16px;
                cursor: pointer;
                color: #909399;
              "
              @click="goCheckAnalysisDetail"
            >
              查看详情>
            </div>
          </div>
          <div
            ref="analysisChart"
            id="analysisChart"
            style="height: 100%; width: 100%; margin-top: -20px;"
          ></div>
        </div>
      </el-col>

      <el-col :span="7">
        <div class="page-content sixthBlock" style="width: 100%">
          <div
            class="sixthTitle"
            style="
              margin-bottom: 20px;
              display: flex;
              justify-content: space-between;
            "
          >
            <span>排行榜</span>
            <div
              style="
                margin-right: 40px;
                font-weight: 400;
                font-size: 16px;
                cursor: pointer;
                color: #909399;
              "
              @click="goCheckMoreDetail"
            >
              查看更多>
            </div>
          </div>
          <div class="studentBlock" style="display: inline-block">
            <span
              :class="studentShow ? 'blueFont' : 'blackFont'"
              style="font-size: 20px; cursor: pointer"
              @click="changeList(1)"
              >个人成绩排行榜</span
            >
            <span class="blueLine" v-show="studentShow"></span>
          </div>

          <div
            class="schoolBlock"
            style="display: inline-block; margin-left: 50px"
          >
            <span
              style="font-size: 20px; cursor: pointer"
              :class="schoolShow ? 'blueFont' : 'blackFont'"
              @click="changeList(2)"
              >学校成绩排行榜</span
            >
            <span class="blueLine" v-show="schoolShow"></span>
          </div>
          <div class="leagueTablesBox">
            <!-- <div id="leagueTablesID" ref="leagueTablesID"></div> -->
            <div
              v-show="studentShow"
              id="stuLeagueTablesID"
              ref="stuLeagueTablesID"
            ></div>
            <div
              v-show="!studentShow"
              id="schLeagueTablesID"
              ref="schLeagueTablesID"
            ></div>
          </div>
          <!-- <div class="achie-content">
            <span style="margin-left: 38px">排名</span>
            <span style="margin-left: 100px">{{ sixthName }}</span>
            <span style="margin-left: 90px">分数</span>
          </div> -->
          <!-- 学生排行榜 -->
          <!-- <div
            class="ranking-content"
            v-show="studentShow"
            v-for="(v, i) in studentRankList"
            :key="v.id"
          >
            <div class="blueCircle">
              <span class="circleFont">{{ i + 1 }}</span>
            </div>
            <span>{{ v.studentName }}</span>
            <span>{{ v.score }}</span>
            <el-divider></el-divider>
          </div> -->
          <!-- 学校排行榜 -->
          <!-- <div
            class="ranking-content"
            v-show="schoolShow"
            v-for="(v, i) in schoolRankList"
            :key="v.id"
          >
            <div class="blueCircle">
              <span class="circleFont">{{ i + 1 }}</span>
            </div>
            <span>{{ v.sysOrgSchoolName }}</span>
            <span>{{ v.averageScore }}</span>
            <el-divider></el-divider>
          </div> -->
        </div>
      </el-col>
    </el-row>

    <!-- <el-row style="margin-top:25px">
      <el-col :span="24">
        <div class="bottomBlock">
          <div class="botFirst">
            <span class="botTitle">总体</span>
            <span class="botContent" style="margin-left:108px;">成绩分布图</span>
          </div>
          <div class="botSecond">
            <span class="botTitle">按学校</span>
            <span class="botContent">成绩分布图</span>
            <span class="botContent">学校平均分柱状图</span>
            <span class="botContent">学校平均分折线图</span>
            <span class="botContent">学校成绩统计表</span>
            <span class="botContent">各小题作答情况表</span>
          </div>
          <div class="botThird">
            <span class="botTitle">按班级</span>
            <span class="botContent">成绩分布图</span>
            <span class="botContent">班级平均分柱状图</span>
            <span class="botContent">班级平均分折线图</span>
            <span class="botContent">班级成绩统计表</span>
            <span class="botContent">各小题作答情况表</span>
          </div>
        </div>
      </el-col>
    </el-row>-->
  </div>
</template>

<script>
import {
  queryCollect,
  queryExamInfo,
  queryAverageScore,
  queryStudentRank,
  querySchoolRank,
  queryCondition,
  queryAchievementDetailCondition,
  getScoreAnalysisSummaryApi
} from "@/api/score/scoreQuery.js";
import * as echarts from "echarts";
export default {
  name: "scoreInfo",
  data() {
    return {
      //父传的参数
      examId: "",
      subjectTypeList: [],
      subjectTypeConditionList: [],
      schoolConditionList: [],
      subjectType: "",
      //图表数据
      chart: {
        //平均分
        averageScore: 0,
        //满分人数
        fullScoreCount: 0,
        //不及格人数
        failScoreCount: 0,
        //及格加满分人数(图表展示需要及格加满分人数，接口只返回及格人数，需要做处理)
        passScoreCount: 0,
      },
      //人数情况 田字格数据
      thatForm: {
        // //成绩异议人数
        // confusionCount: 0,
        //补考人数
        makeupCount: 0,
        // //待审批异议
        // waitApproveConfusionCount: 0,
        // //待重新阅卷
        // waitMarkConfusionCount: 0,
        //报考人数
        examineeCount: 0,
        //已考人数
        hasExamExamineeCount: 0,
        //取消考试人数
        cancelledExamCount: 0,
        //满分
        fullScore: 0,
        //及格分
        passScore: 0,
      },
      form: {
        subject: "",
        school: 0,
      },
      examInfo: {
        examName: "",
        examTime: "",
        examSubject: "",
        examStatus: 2,
      },
      //考试信息字段
      examination: 0,
      examinationNum: 0,
      candidatesNum: 0,
      examTotal: 0,
      count: 5,
      totalPeople: 100,
      charts: {
        chart: "",
        distrChart: "",
        studentChart: "",
        scoreChart: "",
      },
      //父传的参数,
      value: "",
      studentRankList: [],
      schoolRankList: [],
      sixthName: "学生姓名",
      //成绩排行榜显示
      studentShow: true,
      schoolShow: false,
      historyData: [],
      historyName: [],
      historyValue: [],
      //各分段表格
      lowSectionData: [
        {
          lowNumber: 0,
          lowPrecent: 0,
          lowThreshold: 0,
          passNumber: 0,
          passPrecent: 0,
          passThreshold: 0,
        },
      ],
      highSectionData: [
        {
          fineNumber: 0,
          finePrecent: 0,
          fineThreshold: 0,
          highNumber: 0,
          highPrecent: 0,
          highThreshold: 0,
        },
      ],
      markObjectionData: [
        {
          confusionCount: 0,
          waitApproveConfusionCount: 0,
          waitMarkConfusionCount: 0,
        },
      ],
      studentScoreChartList: [], // 学生学校成绩排行榜，用于查看更多传参
      schoolScoreChartList: [],
      scorePeriodEcharts: null,
      leagueTables: null,
      schScoreChartData: "",
      stuScoreChartData: "",
      thresholdArr: [],
      scroeSectionData: [],
      stuLeagueTables: null,
      schLeagueTables: null,

      fullScore:0,

      analysisChart: null,
      examQuestionList: [],
      analysisData: []
    };
  },
  mounted() {
    //获取父路由参数
    this.handleParam();
    //查询考试信息
    this.inquireExam();
    //查询历史平均分并处理
    this.inquireAverageScore();
    //查询过滤条件
    this.inquireCondition();
    // this.inquireArea()
    //获取考试成绩情况和人数情况
    this.queryScoreInfo();
    //默认获取考生、学校排行榜
    this.inquireStudentRank();
    this.inquireSchoolRank();
    // 分析成绩表格
    // this.initAnalysisCharts();
    this.fetchAnalysisData()

    // this.getScorePeriodEchartsIDFunc();

    // this.leagueTablesFunc();
    // this.stuLeagueTablesFunc();
    // this.schLeagueTablesFunc();

    // this.init();
    // //调整窗口大小重新渲染图表
    // window.onresize = () => {
    //   this.reloadChart();
    // }

    document.querySelector("body").setAttribute("style", "background:#F5F6FA");
  },
  beforeDestroy() {
    document.querySelector("body").removeAttribute("style");
  },
  // computed: {
  //   tempSchoolList(){
  //     if(!this.form.area){
  //       return []
  //     }else {
  //       return this.schoolConditionList.filter(it=>it.sysOrgAreaId === this.form.area)
  //     }
  //   }
  // },

  filters: {
    //学科过滤
    subjectFilter(val) {
      switch (val) {
        case 1:
          return `物理`;
        case 2:
          return `生物`;
        case 3:
          return `化学`;
      }
    },

    //考试状态过滤
    examStatus(val) {
      switch (val) {
        case 2:
          return `等待考试`;
        case 3:
          return `正在考试`;
        case 4:
          return `考试完成`;
        case 5:
          return `等待补考`;
        case 6:
          return `正在补考`;
        case 7:
          return `考试终止`;
      }
    },

    //小数点补齐
    pointSupply(val) {
      if (!val.toString().includes(".") && val != 0) {
        return `${val}.0`;
      } else {
        return val;
      }
    },
  },
  methods: {
    //处理接收的参数
    handleParam() {
      this.examId = Number(this.$route.query.examId);
      //将字符串数组转换成整型数组
      if (this.$route.query.subject instanceof Object) {
        this.subjectTypeList = this.turnNum(this.$route.query.subject);
      } else {
        this.subjectTypeList = this.turnNum([this.$route.query.subject]);
      }
      //处理标题学科类型展示
      this.examInfo.examSubject = this.subjectListFilter(this.subjectTypeList);
      //处理筛选框默认选中学科列表第一个
      this.form.subject = this.subjectTypeList[0];
      //更新默认请求参数中的subjectType
      this.subjectType = this.subjectTypeList[0];
    },

    //处理学科数组方法
    subjectListFilter(list) {
      let str = "";
      for (let i = 0; i < list.length; i++) {
        switch (list[i]) {
          case 1:
            str += ` 物理`;
            break;
          case 2:
            str += ` 生物`;
            break;
          case 3:
            str += ` 化学`;
            break;
        }
      }
      return str;
    },

    //处理时间方法
    dateFormat(val) {
      const dt = new Date(val);
      const y = dt.getFullYear();
      const m = (dt.getMonth() + 1 + "").padStart(2, "0");
      const d = (dt.getDate() + "").padStart(2, "0");
      //padStart(2,'0') 意思是不足两位自动补0
      const hh = (dt.getHours() + "").padStart(2, "0");
      const mm = (dt.getMinutes() + "").padStart(2, "0");
      const ss = (dt.getSeconds() + "").padStart(2, "0");
      return `${y}-${m}-${d} ${hh}:${mm}:${ss}`;
    },

    //处理历史平均分数组
    historyScore() {
      let dataList = this.historyData;
      var numList = [];
      var proList = [];
      dataList.forEach((v, i) => {
        Object.keys(v).forEach((v) => {
          if (typeof dataList[i][v] === "number") {
            numList.push(dataList[i][v]);
          } else {
            proList.push(dataList[i][v]);
          }
        });
      });
      this.historyName = proList;
      this.historyValue = numList;
    },

    //将字符串数组转换成整型数组
    turnNum(list) {
      return list.map(Number);
    },
    initChart() {
      var chart = this.$echarts.init(this.$refs.chart);
      this.charts.chart = chart;

      //ECHARTS1
      // 指定图表的配置项和数据
      var option = {
        tooltip: {
          trigger: "item",
        },
        title: {
          //显示策略，默认值true,可选为：true（显示） | false（隐藏）
          show: true,
          //主标题文本
          text: "成绩情况",
          //水平安放位置
          x: 20,
          //垂直安放位置
          top: 20,
          //主标题文本样式
          textStyle: {
            fontSize: 17,
          },
        },
        series: [
          {
            type: "pie",
            radius: ["55%", "70%"],
            center: ["50%", "50%"],
            avoidLabelOverlap: false,
            hoverAnimation: false,
            label: {
              normal: {
                show: false,
                position: "inside",
                color: "#4c4a4a",
              },
            },
            itemStyle: {
              shadowColor: "#A1D1FD",
              shadowBlur: 10,
              shadowOffsetX: 5,
              shadowOffsetY: 5,
            },
            lableLine: {
              normal: { show: false },
              emphasis: { show: false },
              tooltip: { show: false },
            },
            data: [
              {
                value: this.dateFormat.fullScore - this.examTotal,
                name: "",
                color: "#FFFFF",
              },
              { value: this.examTotal, name: "平均分" },
            ],
          },
        ],
        graphic: [
          {
            //环形图中间添加文字
            type: "text", //通过不同top值可以设置上下显示
            left: "center",
            top: "38%",
            style: {
              text: `${this.chart.averageScore}`,
              textAlign: "center",
              fill: "#101010", //文字的颜色
              width: 35,
              height: 35,
              fontSize: 60,
              fontFamily: "Microsoft YaHei",
            },
          },
          {
            //环形图中间添加文字
            type: "image", //通过不同top值可以设置上下显示
            left: "center",
            top: "53%",
            style: {
              image: require("@/assets/rectPic.png"),
              width: 70,
              height: 10,
              color: "#101010",
            },
          },
          {
            //环形图中间添加文字
            type: "text", //通过不同top值可以设置上下显示
            left: "center",
            top: "58%",
            style: {
              text: "平均成绩",
              textAlign: "center",
              fill: "#606266", //文字的颜色
              width: 10,
              height: 10,
              fontSize: 18,
              fontFamily: "Microsoft YaHei",
            },
          },
        ],
        color: ["#F5F6FA", "#2280D7"],
      };
      // 使用刚指定的配置项和数据显示图表。
      chart.setOption(option);

      //ECHARTS2
      var distrChart = this.$echarts.init(this.$refs.distrChart);
      this.charts.distrChart = distrChart;
      var distrOption = {
        backgroundColor: "#FFFFFF",
        title: {
          text: "分数分布情况",
          top: 20,
          left: 20,
          textStyle: {
            fontSize: 17,
          },
        },
        tooltip: {
          trigger: "item",
        },
        visualMap: {
          show: false,
          min: 80,
          max: 600,
          inRange: {
            colorLightness: [0, 1],
          },
        },
        series: [
          // {
          //   name: '',
          //   type: 'pie',
          //   radius: '65%',
          //   center: ['50%', '60%'],
          //   data: [
          //     { value: this.failScoreCount, name: '不及格人数', itemStyle: { color: '#00A5E8', shadowColor: '#00A5E8', shadowBlur: 8, } },
          //     { value: this.fullScoreCount, name: '满分人数', itemStyle: { color: '#2280D7', shadowColor: '#2280D7', shadowBlur: 8, } },
          //     { value: this.passScoreCount, name: '及格满分人数', itemStyle: { color: '#00C4D8', shadowColor: '#00C4D8', shadowBlur: 8, } },
          //   ].sort(function (a, b) {
          //     return a.value - b.value;
          //   }),
          //   roseType: 'radius',
          //   label: {
          //     color: '#FFFFFF',
          //     position: 'inner',
          //     fontSize: 16,
          //     formatter: '{c}',
          //   },
          //   animationType: 'scale',
          //   animationEasing: 'elasticOut',
          //   animationDelay: function (idx) {
          //     return Math.random() * 200;
          //   }
          // },
          // //为了显示两个label:value显示在图表内 name显示在图表外
          // {
          //   name: '',
          //   type: 'pie',
          //   radius: '65%',
          //   center: ['50%', '60%'],
          //   data: [
          //     { value: this.failScoreCount, name: '不及格人数', itemStyle: { color: '#00A5E8', shadowColor: '#00A5E8', shadowBlur: 8, } },
          //     { value: this.fullScoreCount, name: '满分人数', itemStyle: { color: '#2280D7', shadowColor: '#2280D7', shadowBlur: 8, } },
          //     { value: this.passScoreCount, name: '及格满分人数', itemStyle: { color: '#00C4D8', shadowColor: '#00C4D8', shadowBlur: 8, } },
          //   ].sort(function (a, b) {
          //     return a.value - b.value;
          //   }),
          //   roseType: 'radius',
          //   label: {
          //     color: '#909399',
          //     position: 'outside',
          //     fontSize: 16,
          //     formatter: '{b}',
          //   },

          //   // labelLine: {
          //   //   lineStyle: {
          //   //     color: 'rgba(255, 255, 255, 0.3)'
          //   //   },
          //   //   smooth: 0.2,
          //   //   length: 10,
          //   //   length2: 20
          //   // },
          //   animationType: 'scale',
          //   animationEasing: 'elasticOut',
          //   animationDelay: function (idx) {
          //     return Math.random() * 200;
          //   }
          // }
          {
            name: "分数分布情况",
            type: "pie",
            radius: "65%",
            center: ["50%", "60%"],
            data: [
              {
                value: this.failScoreCount,
                name: `不及格人数\n${this.failScoreCount}人`,
                itemStyle: {
                  color: "#3ECBF4",
                  shadowColor: "#3ECBF4",
                  shadowBlur: 8,
                },
              },
              {
                value: this.fullScoreCount,
                name: `满分人数\n${this.fullScoreCount}人`,
                itemStyle: {
                  color: "#586FF5",
                  shadowColor: "#586FF5",
                  shadowBlur: 8,
                },
              },
              {
                value: this.passScoreCount,
                name: `及格-满分人数\n${this.passScoreCount}人`,
                itemStyle: {
                  color: "#F5AC58",
                  shadowColor: "#F5AC58",
                  shadowBlur: 8,
                },
              },
            ],
            // emphasis: {
            //   itemStyle: {
            //     shadowBlur: 10,
            //     shadowOffsetX: 0,
            //     shadowColor: 'rgba(0, 0, 0, 0.5)'
            //   }
            // }
          },
          {
            name: "分数分布情况",
            type: "pie",
            radius: "65%",
            center: ["50%", "60%"],
            data: [
              {
                value: this.failScoreCount,
                name: `不及格人数\n${this.failScoreCount}人`,
                itemStyle: {
                  color: {
                    type: "radial",
                    x: 0,
                    y: 1,
                    r: 2.5,
                    colorStops: [
                      {
                        offset: 0,
                        color: "rgb(62,203,244)", // 0% 处的颜色
                        // color:"red"
                      },
                      {
                        offset: 1,
                        // color:"blue"
                        color: "rgb(116,217,246)", // 100% 处的颜色
                      },
                    ],
                    global: false, // 缺省为 false
                  },
                  // shadowColor: "#fff",
                  // shadowBlur: 8,
                },
              },
              {
                value: this.fullScoreCount,
                name: `满分人数\n${this.fullScoreCount}人`,
                itemStyle: {
                  color: {
                    type: "radial",
                    x: 0,
                    y: 1,
                    r: 1,
                    colorStops: [
                      {
                        offset: 0,
                        color: "rgb(88,111,245)", // 0% 处的颜色
                        // color:"red"
                      },
                      {
                        offset: 1,
                        color: "rgb(132,148,246)", // 100% 处的颜色
                        // color:"blue"
                      },
                    ],
                    global: false, // 缺省为 false
                  },
                  // shadowColor: "#fff",
                  // shadowBlur: 8,
                },
              },
              {
                value: this.passScoreCount,
                name: `及格-满分人数\n${this.passScoreCount}人`,
                itemStyle: {
                  color: {
                    type: "radial",
                    x: 0.5,
                    y: 0.5,
                    r: 0.5,
                    colorStops: [
                      {
                        offset: 0,
                        color: "rgb(245,172,88)", // 0% 处的颜色
                        // color:"red"
                      },
                      {
                        offset: 1,
                        color: "rgb(247,192,130)", // 100% 处的颜色
                        //  color:"blue"
                      },
                    ],
                    global: false, // 缺省为 false
                  },
                  // shadowColor: "#fff",
                  // shadowBlur: 8,
                },
              },
            ],
            label: {
              color: "#FFFFFF",
              position: "inner",
              fontSize: 15,
              formatter: function (params) {
                let val = "";
                if (params.percent) {
                  val = params.percent + "%";
                } else {
                  val = "0%";
                }
                return val;
              },
            },
          },
        ],
      };
      distrChart.setOption(distrOption);

      //ECHARTS4
      var scoreChart = this.$echarts.init(this.$refs.scoreChart);
      this.charts.scoreChart = scoreChart;

      var scoreOption = {
        title: {
          text: "历史平均分",
          x: 20,
          top: 15,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
        },
        axisLabel: {
           //x轴文字的配置
          show: true,
          interval: 0, //使x轴文字显示全
          formatter: (params) => {
              let newParamsName = ''
              let paramsNameNumber = params.length
              let provideNumber = 4 //一行显示几个字
              let rowNumber = Math.ceil(paramsNameNumber / provideNumber)
              if (paramsNameNumber > provideNumber) {
                for (let p = 0; p < rowNumber; p++) {
                  let tempStr = ''
                  let start = p * provideNumber
                  let end = start + provideNumber
                  if (p == rowNumber - 1) {
                    tempStr = params.substring(start, paramsNameNumber)
                  } else {
                    tempStr = params.substring(start, end) + '\n'
                  }
                  if(p == 3 && p !== rowNumber - 1) {
                    tempStr = tempStr.substring(0, 1) + "..."
                    newParamsName += tempStr
                    break
                  } else {
                    newParamsName += tempStr
                  }
                }
              } else {
                newParamsName = params
              }
              return newParamsName
            },
        },
        xAxis: {
          type: "category",
          //控制X轴刻度间隔
          axisLabel: { interval: 0 },
          data: this.historyName,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: this.historyValue,
            type: "line",
            smooth: true,
            name: "分数",
          },
        ],
        color: ["#1890ff"],
      };
      scoreChart.setOption(scoreOption);

      // //ECHARTS3
      // var studentChart = this.$echarts.init(this.$refs.studentChart);
      // this.charts.studentChart = studentChart;
      // var studentOption = {
      //   title: [
      //     {
      //       text: '人数',
      //       x: 20,
      //       top: 20,
      //     },
      //   ],
      //   xAxis: {
      //     type: 'category',
      //     data: ['≥9', '8-9', '7-8', '6-7', '<6']
      //   },
      //   yAxis: {
      //     type: 'value'
      //   },
      //   color: {
      //     x: 0,
      //     y: 0,
      //     x2: 0,
      //     y2: 1,
      //     colorStops: [
      //       { offset: 0, color: '#2280D7' },
      //       { offset: 0.5, color: '#178CF8' },
      //       { offset: 1, color: '#75BCFF' }
      //     ]

      //   },
      //   series: [
      //     {
      //       data: [0, 0, 0, 0, 5],
      //       type: 'bar',
      //       barWidth: 35,
      //     }
      //   ]
      // };
      // studentChart.setOption(studentOption);
    },
    refresh() {
      this.reloadChart();
    },
    reloadChart() {
      this.charts.chart.resize();
      this.charts.distrChart.resize();
      this.charts.studentChart.resize();
      this.charts.scoreChart.resize();
    },

    //查看成绩明细
    seeExamDetailed() {
      this.$router.push({
        name: "scoreDetailed",
        query: { examId: this.examId, subject: this.subjectTypeList },
      });
    },

    //跳转人数统计页面
    peopleCounting() {
      this.$router.push({
        name: "peopleCounting",
        query: {
          examId: this.examId,
          subject: this.form.subject,
          examName: this.examInfo.examName,
        },
      });
    },

    //跳转分数统计页面
    scoreCounting() {
      this.$router.push({
        name: "scoreCounting",
        query: { examId: this.examId, subject: this.form.subject },
      });
    },

    // 跳转到查看更多页面
    goCheckMoreDetail() {
      this.$router.push({
        name: "scoreChartDetail",
        query: {
          examId: this.examId,
          subject: this.form.subject,
          examName: this.examInfo.examName,
          studentScoreChartList: this.studentScoreChartList,
          schoolScoreChartList: this.schoolScoreChartList,
        },
      });
    },

    //查询成绩汇总信息
    queryScoreInfo() {
      let sysOrgSchoolId = "";
      this.scroeSectionData = [];
      this.thresholdArr = [];
      if (this.form.school != 0) {
        sysOrgSchoolId = this.form.school;
      }
      let ScoreInfoParam = {
        subjectType: this.form.subject,
        sysOrgSchoolId: sysOrgSchoolId,
      };
      queryCollect(this.examId, ScoreInfoParam).then((res) => {
        let { data, success } = res;
        if (success) {
          if (data != null) {
            console.log(data);
            this.chart.averageScore = data.averageScore;
            this.markObjectionData[0].confusionCount = data.confusionCount;
            this.thatForm.makeupCount = data.makeupCount;
            this.markObjectionData[0].waitApproveConfusionCount =
              data.waitApproveConfusionCount;
            this.markObjectionData[0].waitMarkConfusionCount =
              data.waitMarkConfusionCount;
            this.thatForm.fullScore =
              data.examAchievementSegmentStatistics.fullScore;
              this.fullScore = data.examAchievementSegmentStatistics.fullScore;
            this.thatForm.passScore =
              data.examAchievementSegmentStatistics.passScore;
            //取消考试
            this.thatForm.examineeCount = data.examineeCount;
            this.thatForm.hasExamExamineeCount = data.hasExamExamineeCount;
            this.thatForm.cancelledExamCount = data.cancelledExamCount;
            //各分段人数
            this.lowSectionData[0].lowNumber =
              data.examAchievementSegmentStatistics.lowScoreCount;
            this.lowSectionData[0].lowPrecent =
              data.examAchievementSegmentStatistics.lowScorePercent == 0
                ? 0 + "%"
                : data.examAchievementSegmentStatistics.lowScorePercent + "%";
            this.lowSectionData[0].lowThreshold =
              data.examAchievementSegmentStatistics.lowScoreThreshold;
            this.thresholdArr.push(
              `≤${this.handleFormatPercent(
                this.lowSectionData[0].lowThreshold
              )}分`
            );
            this.scroeSectionData.push([
              +data.examAchievementSegmentStatistics.lowScorePercent,
              this.thresholdArr[0],
              +this.lowSectionData[0].lowNumber,
            ]);
            this.lowSectionData[0].passNumber =
              data.examAchievementSegmentStatistics.passCount;
            this.lowSectionData[0].passPrecent =
              data.examAchievementSegmentStatistics.passPercent == 0
                ? 0 + "%"
                : data.examAchievementSegmentStatistics.passPercent + "%";
            this.lowSectionData[0].passThreshold =
              data.examAchievementSegmentStatistics.passScore;
            this.thresholdArr.push(
              `≥${this.handleFormatPercent(
                this.lowSectionData[0].passThreshold
              )}分`
            );
            this.scroeSectionData.push([
              +data.examAchievementSegmentStatistics.passPercent,
              this.thresholdArr[1],
              +this.lowSectionData[0].passNumber,
            ]);
            this.highSectionData[0].fineNumber =
              data.examAchievementSegmentStatistics.excellentScoreCount;
            this.highSectionData[0].finePrecent =
              data.examAchievementSegmentStatistics.excellentScorePercent == 0
                ? 0 + "%"
                : data.examAchievementSegmentStatistics.excellentScorePercent +
                  "%";
            this.highSectionData[0].fineThreshold =
              data.examAchievementSegmentStatistics.excellentScoreThreshold;
            this.thresholdArr.push(
              `≥${this.handleFormatPercent(
                this.highSectionData[0].fineThreshold
              )}分`
            );
            this.scroeSectionData.push([
              +data.examAchievementSegmentStatistics.excellentScorePercent,
              this.thresholdArr[2],
              +this.highSectionData[0].fineNumber,
            ]);
            this.highSectionData[0].highNumber =
              data.examAchievementSegmentStatistics.highScoreCount;
            this.highSectionData[0].highPrecent =
              data.examAchievementSegmentStatistics.highScorePercent == 0
                ? 0 + "%"
                : data.examAchievementSegmentStatistics.highScorePercent + "%";
            this.highSectionData[0].highThreshold =
              data.examAchievementSegmentStatistics.highScoreThreshold;
            this.thresholdArr.push(
              `≥${this.handleFormatPercent(
                this.highSectionData[0].highThreshold
              )}分`
            );
            this.scroeSectionData.push([
              +data.examAchievementSegmentStatistics.highScorePercent,
              this.thresholdArr[3],
              +this.highSectionData[0].highNumber,
            ]);
            //图表数据
            this.fullScoreCount = data.fullScoreCount;
            this.failScoreCount = data.failPassCount;
            this.passScoreCount = data.passAndFullBetweenCount;
            this.examTotal = data.averageScore;
          } else {
            this.chart.averageScore = 0;
            this.thatForm.confusionCount = 0;
            this.thatForm.makeupCount = 0;
            this.thatForm.waitApproveConfusionCount = 0;
            this.thatForm.waitMarkConfusionCount = 0;
            this.thatForm.fullScore = 0;
            this.thatForm.passScore = 0;
            //图表数据
            this.fullScoreCount = 0;
            this.failScoreCount = 0;
            this.passScoreCount = 0;
            this.examTotal = 0;
          }
          this.getScorePeriodEchartsIDFunc();
          this.initChart();
          this.stuLeagueTablesFunc();
          this.schLeagueTablesFunc();
        } else {
        }
      });
    },

    //查询考试信息
    inquireExam() {
      queryExamInfo(this.examId).then((res) => {
        let { success, data } = res;
        if (success) {
          this.examInfo.examName = data.examName;
          this.examInfo.examStatus = data.examStatus;
          //过滤时间戳并且拼接
          let startTime = "未开始";
          let endTime = "未结束";
          if (data.examStartTime != 0) {
            startTime = this.dateFormat(data.examStartTime);
          }
          if (data.examEndTime != 0) {
            endTime = this.dateFormat(data.examEndTime);
          }
          this.examInfo.examTime = `${startTime} —— ${endTime}`;
          //考试信息板块数据
          this.candidatesNum = data.registerExamCount;
          this.examinationNum = data.sysOrgExamPlaceCount;
          this.examination = data.examAnswerTime;
        }
      });
    },

    //查询历史平均分
    inquireAverageScore() {
      let sysOrgSchoolId = "";
      if (this.form.school != 0) {
        sysOrgSchoolId = this.form.school;
      }
      let averageScoreParam = {
        subjectType: this.form.subject,
        sysOrgSchoolId: sysOrgSchoolId,
      };
      queryAverageScore(this.examId, averageScoreParam).then((res) => {
        let { success, data } = res;
        if (success) {
          if (data.length > 0) {
            this.historyData = data;
            //处理历史平均分数组
            this.historyScore();
          } else {
            this.historyData = [];
            this.historyScore();
          }
          this.initChart();
        }
      });
    },

    //查询考生排行榜
    inquireStudentRank() {
      let sysOrgSchoolId = "";
      if (this.form.school != 0) {
        sysOrgSchoolId = this.form.school;
      }
      let studentRankParam = {
        subjectType: this.form.subject,
        sysOrgSchoolId: sysOrgSchoolId,
        sysOrgAreaId: "",
        sysOrgSchoolClassId: "",
        rankingType: 1,
        sortType: 2,
      };
      // 接口更新，参数默认
      let pageIndex = 1,
        pageSize = 10;
      queryStudentRank(this.examId, pageIndex, pageSize, studentRankParam).then(
        (res) => {
          // console.log(res,'学生排行榜')
          let { success, data } = res;
          if (success) {
            if (data.length > 0) {
              //目前逻辑只需要排行榜前5 做截取处理
              //   data: [
              //   { name: "长鹏中学1", num: "10", value: "6.5" },
              //   { name: "长鹏中学2", num: "9", value: "6.5" },
              //   { name: "长鹏中学3", num: "8", value: "7.5" },
              //   { name: "长鹏中学4", num: "7", value: "8.5" },
              //   { name: "长鹏中学5", num: "6", value: "8.5" },
              //   { name: "长鹏中学6", num: "5", value: "9.5" },
              //   { name: "长鹏中学7", num: "4", value: "9.5" },
              //   { name: "长鹏中学8", num: "3", value: "10" },
              //   { name: "长鹏中学9", num: "2", value: "10" },
              //   { name: "长鹏中学10", num: "1", value: "10" },
              // ],
              this.studentRankList = data.slice(0, 5);
              this.stuScoreChartData = [];
              data.forEach((item) => {
                this.stuScoreChartData.push({
                  name: item.studentName,
                  num: item.ranking + "",
                  value: item.score + "",
                });
              });
              this.stuLeagueTablesFunc();
              this.stuScoreChartData.length && this.stuScoreChartData.reverse();
              this.studentScoreChartList = data;
            } else {
              this.stuScoreChartData = [];
              this.stuLeagueTablesFunc();
            }
          }
        }
      );
    },

    //查询学校排行榜
    inquireSchoolRank() {
      let schoolRankParam = {
        subjectType: this.form.subject,
        sysOrgSchoolId: "",
        sysOrgAreaId: "",
        sysOrgSchoolClassId: "",
        rankingType: 2,
        sortType: 2,
      };
      querySchoolRank(this.examId, 1, 10, schoolRankParam).then((res) => {
        // console.log(res, "学校排行榜");
        let { success, data } = res;
        if (success) {
          if (data.length > 0) {
            //目前逻辑只需要排行榜前5 做截取处理
            this.schoolRankList = data.slice(0, 5);
            this.schScoreChartData = [];
            data.forEach((item) => {
              this.schScoreChartData.push({
                name: item.sysOrgSchoolName,
                num: item.ranking + "",
                value: item.averageScore + "",
              });
            });
            this.schLeagueTablesFunc();
            this.schScoreChartData.length && this.schScoreChartData.reverse();
            this.schoolScoreChartList = data;
          }
        }
      });
    },
    //查询成绩过滤条件
    inquireCondition() {
      queryCondition(this.examId).then((res) => {
        let { success, data } = res;
        console.log(data,"data")
        if (success) {
          this.subjectTypeConditionList = data.subjectTypeList;
          this.schoolConditionList = []
          data.examAchievementAreaConditionDTOS.forEach(it=> {
            this.schoolConditionList.push(...it.examAchievementSchoolConditionDTOList)
          })
        }
      });
    },
    //切换排行榜
    changeList(state) {
      if (state == 1) {
        this.studentShow = true;
        this.schoolShow = false;
        this.sixthName = "学生姓名";
      } else {
        this.studentShow = false;
        this.schoolShow = true;
        this.sixthName = "学校名称";
      }
    },
    conditionChangeQuery() {
      this.queryScoreInfo();
      this.inquireAverageScore();
      this.inquireStudentRank();
      this.inquireSchoolRank();
      this.fetchAnalysisData()
    },

    lookObjectionView() {
      this.$router.push({
        path: "/examManage/examObjectionView",
        query: {
          examId: this.examId,
          subject: this.subjectTypeList,
        },
      });
    },

    // 各阶段人数成绩分布
    getScorePeriodEchartsIDFunc() {
      this.scorePeriodEcharts = echarts.init(
        document.getElementById("scorePeriodEchartsID")
      );
      let options = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: (options) => {
            // console.log(options[0]);
            let value = options[0].axisValue;
            let percent = options[0].data[0];
            let stuNum = options[0].data[2];
            // if (value == "≤4.0分") {
            //   return "0~4.0分"+"<br />" + percent + "% &nbsp;" + stuNum + "人";
            // } else if (value == "≥6.0分") {
            //   return "6.0~7.9分<br />" + percent + "% &nbsp;" + stuNum + "人";
            // } else if (value == "≥8.0分") {
            //   return "8.0~8.9分<br />" + percent + "% &nbsp;" + stuNum + "人";
            // } else {
            //   return "9.0~10.0分<br />" + percent + "% &nbsp;" + stuNum + "人";
            // }
            let scoreArr = this.thresholdArr.map((item) => +item.substr(1, 3));
            for (let i = 0; i < this.thresholdArr.length; i++) {
              let left = "",
                right = "";
              if (i === 0) {
                left = "0分";
                right = this.thresholdArr[0].substring(1, 5);
              } else {
                left = this.thresholdArr[i].substring(1, 5);
                right = `${this.fullScore.toFixed(1)}分`;
              }

              if (value == this.thresholdArr[i]) {
                return (
                  left +
                  "~" +
                  right +
                  "<br />" +
                  percent +
                  "% &nbsp;" +
                  stuNum +
                  "人"
                );
              }
            }
          },
        },
        legend: {},
        grid: {
          left: "3%",
          right: "10%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          axisLabel: {
            formatter: "{value}%",
            fontSize: 14,
          },
          axisLine: {
            show: true,
          },
        },
        yAxis: {
          type: "category",
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 14,
          },
          // data: ["≤4.0分", "≥6.0分", "≥8.0分", "≥9.0分"],
          data: this.thresholdArr,
        },
        series: [
          {
            type: "bar",
            // data: [
            //   [20, "≤4.0分", 2],
            //   [30, "≥6.0分", 6],
            //   [35, "≥8.0分", 10],
            //   [10, "≥9.0分", 2],
            // ],
            data: this.scroeSectionData,
            color: "rgb(24,144,255)",
            barWidth: "70%",
            barCategoryGap: "30%",
            label: {
              show: true,
              position: "right",
              formatter: (val) => {
                return val.data[2] + "人";
              },
              fontSize: 14,
            },
          },
        ],
      };
      this.scorePeriodEcharts.setOption(options);
    },

    stuLeagueTablesFunc() {
      this.stuLeagueTables = this.$echarts.init(this.$refs.stuLeagueTablesID);
      let yAxisData = [];
      this.stuScoreChartData.length && this.stuScoreChartData.forEach((item, i) => {
        yAxisData.push(i + 1);
      });
      let option = {
        tooltip: {
          trigger: "item",
          formatter: "{b}:{c}分",
        },
        grid: {
          right: "20%",
        },
        xAxis: {
          type: "value",
          max: `${this.fullScore}`,
          axisLine: {
            show: true,
          },
        },
        yAxis: {
          type: "category",
          data: yAxisData.reverse(),
          axisLine: {
            symbol: ["none", "arrow"], // 只在末端显示箭头
            symbolSize: [5, 5],
          },
          axisTick: {
            show: false,
          },
        },
        color: [
          "rgb(255,110,118)",
          "rgb(88,217,249)",
          "rgb(255,138,68)",
          "rgb(72,146,255)",
          "rgb(124,255,178)",
          "rgb(140,71,227)",
        ],
        series: [
          {
            // data: [120, 200, 150, 80, 70, 110, 130],
            // data: [
            //   { name: "长鹏中学1", num: "10", value: "6.5" },
            //   { name: "长鹏中学2", num: "9", value: "6.5" },
            //   { name: "长鹏中学3", num: "8", value: "7.5" },
            //   { name: "长鹏中学4", num: "7", value: "8.5" },
            //   { name: "长鹏中学5", num: "6", value: "8.5" },
            //   { name: "长鹏中学6", num: "5", value: "9.5" },
            //   { name: "长鹏中学7", num: "4", value: "9.5" },
            //   { name: "长鹏中学8", num: "3", value: "10" },
            //   { name: "长鹏中学9", num: "2", value: "10" },
            //   { name: "长鹏中学10", num: "1", value: "10" },
            // ],
            data:  this.stuScoreChartData.length && this.stuScoreChartData.reverse(),
            type: "bar",
            encode: {
              x: "name",
              y: "value",
            },
            barWidth: "60%",
            // color: "rgb(24,144,255)",
            colorBy: "data",
            label: {
              show: true,
              position: "right",
              formatter: "{b}:{c}分",
              color: "#000",
            },
          },
        ],
        dataZoom: [
          {
            type: "inside",
            yAxisIndex: [0],
          },
        ],
      };

      this.stuLeagueTables.setOption(option);
    },
    schLeagueTablesFunc() {
      this.schLeagueTables = this.$echarts.init(this.$refs.schLeagueTablesID);
      let yAxisData = [];
      this.schScoreChartData.length && this.schScoreChartData.forEach((item, i) => {
        yAxisData.push(i + 1);
      });
      console.log(this.fullScore)
      let option = {
        tooltip: {
          trigger: "item",
          formatter: "{b}:{c}分",
        },
        grid: {
          right: "15%",
        },
        xAxis: {
          type: "value",
          max: `${this.fullScore}`,
          axisLine: {
            show: true,
          },
        },
        yAxis: {
          type: "category",
          data: yAxisData.reverse(),
          axisLine: {
            symbol: ["none", "arrow"], // 只在末端显示箭头
            symbolSize: [5, 5],
          },
          axisTick: {
            show: false,
          },
        },
        color: [
          "rgb(72,146,255)",
          "rgb(124,255,178)",
          "rgb(140,71,227)",
          "rgb(255,110,118)",
          "rgb(88,217,249)",
          "rgb(255,138,68)",
        ],
        series: [
          {
            // data: [120, 200, 150, 80, 70, 110, 130],
            // data: [
            //   { name: "长鹏中学1", num: "10", value: "6.5" },
            //   { name: "长鹏中学2", num: "9", value: "6.5" },
            //   { name: "长鹏中学3", num: "8", value: "7.5" },
            //   { name: "长鹏中学4", num: "7", value: "8.5" },
            //   { name: "长鹏中学5", num: "6", value: "8.5" },
            //   { name: "长鹏中学6", num: "5", value: "9.5" },
            //   { name: "长鹏中学7", num: "4", value: "9.5" },
            //   { name: "长鹏中学8", num: "3", value: "10" },
            //   { name: "长鹏中学9", num: "2", value: "10" },
            //   { name: "长鹏中学10", num: "1", value: "10" },
            // ],
            data: this.schScoreChartData.length && this.schScoreChartData.reverse(),
            type: "bar",
            encode: {
              x: "name",
              y: "value",
            },
            barWidth: "60%",
            // color: "rgb(24,144,255)",
            colorBy: "data",
            label: {
              show: true,
              position: "right",
              formatter: "{b}:{c}分",
              color: "#000",
            },
          },
        ],
        dataZoom: [
          {
            type: "inside",
            yAxisIndex: [0],
          },
        ],
      };

      this.schLeagueTables.setOption(option);
    },

    fetchAnalysisData() {
      let param = {
        subjectType: this.form.subject,
      };
      if (this.form.area) {
        param = {
          ...param,
          sysOrgAreaId: this.form.area,
        };
      }
      if (this.form.school) {
        param = {
          ...param,
          sysOrgSchoolId: this.form.school,
        };
      }
      getScoreAnalysisSummaryApi(this.examId, param).then((res) => {
        if (res.success) {
          this.analysisData = res.data.filter(
            (it) => it.subjectType == this.form.subject
          );
          this.initAnalysisCharts()
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 分析表
    initAnalysisCharts(){
      this.analysisChart = this.$echarts.init(this.$refs.analysisChart);
      let options = {
        // tooltip: {
        //   trigger: 'axis',
        //   formatter: function(params){
        //     let str = ""
        //     params.forEach((it, i)=>{
        //       str += i + 1 + "、" + it.seriesName + "<br />" + it.axisValue + " : " + it.value + "人" + "<br />"
        //     })
        //     return str
        //   }
        // },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          }
        },
        legend: {
          bottom: 60,
          left: 'center',
        },
        grid: {
          bottom: "25%",
        },
        axisLabel: {
           //x轴文字的配置
          show: true,
          interval: 0, //使x轴文字显示全
          formatter: (params) => {
              let newParamsName = ''
              let paramsNameNumber = params.length
              let provideNumber = 6 //一行显示几个字
              let rowNumber = Math.ceil(paramsNameNumber / provideNumber)
              if (paramsNameNumber > provideNumber) {
                for (let p = 0; p < rowNumber; p++) {
                  let tempStr = ''
                  let start = p * provideNumber
                  let end = start + provideNumber
                  if (p == rowNumber - 1) {
                    tempStr = params.substring(start, paramsNameNumber)
                  } else {
                    tempStr = params.substring(start, end) + '\n'
                  }
                  newParamsName += tempStr
                }
              } else {
                newParamsName = params
              }
              return newParamsName
            },
        },
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: false
          },
          data: this.analysisData.map(it=>it.testName),
        },
        yAxis: [
          {
            type: 'value',
            name: '满分率',
            position: 'left',
            alignTicks: true,
            axisLine: {
              show: false,
            },
            axisLabel: {
              formatter: '{value}%'
            }
          },
          {
            type: 'value',
            name: '人次',
            position: 'right',
            alignTicks: true,
            axisLine: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: '总满分人次',
            type: 'bar',
            barWidth: 20,
            yAxisIndex: 1,
            data: this.analysisData.map(it=>it.fullScoreCount),
          },
          {
            name: '总失分人次',
            type: 'bar',
            barWidth: 20,
            yAxisIndex: 1,
            data: this.analysisData.map(it=>it.loseScoreCount),
          },
          {
            name: '满分率',
            type: 'line',
            data: this.analysisData.map(it=>it.fullScoreRate),
            smooth: true
          }
        ]
      }
      this.analysisChart.setOption(options);
    },
    // 跳转至分析详情页
    goCheckAnalysisDetail(){
      this.$router.push({
        name: "analysisDetail",
        query: {
          examId: this.examId,
          schoolConditionList: JSON.stringify(this.schoolConditionList),
          sysOrgAreaList: JSON.stringify(this.sysOrgAreaList),
          examQuestionList: JSON.stringify(this.examQuestionList),
          subjectTypeConditionList: JSON.stringify(this.subjectTypeConditionList),
        },
      });
    },

    handleFormatPercent(val) {
      if (!val.toString().includes(".") && val != 0) {
        return `${val}.0`;
      } else {
        return val;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.blueFont {
  color: #1890ff;
}
.blackFont {
  color: #606266;
}
.el-divider {
  background-color: #c8dbec;
  width: 92%;
  margin-top: 5px;
}

.el-row {
  margin-bottom: 10px;
}
.el-row:last-child {
  margin-bottom: 0;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.app {
  padding: 8px 16px;
  background-color: #ffffff;
  border-radius: 0 4px 4px 0;
  border-left: 10px solid #2295ff;
  box-sizing: border-box;
  height: 139px;
  margin-top: 10px;
}

.app > div:nth-child(2) {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.examState {
  width: 80px;
  height: 25px;
  display: inline-block;
  font-size: 15px !important;
  background-color: #1890ff;
  color: #ffffff;
  border-radius: 15px;
  line-height: 25px;
  text-align: center;
}
.examInfoHead {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}
.examName {
  font-size: 28px;
  color: #778ca2;
  font-weight: bold;
  letter-spacing: 2px;
  margin: 0 10px;
}

.firstBlock,
.secondBlock,
.thirdBlock {
  border: 1px solid #ffffff !important;
  background-color: #ffffff;
  height: 450px;
  border-radius: 5px 5px 0px 0px;
  opacity: 1;
}

.fourthBlock,
.fifthBlock,
.sixthBlock {
  border: 1px solid #ffffff !important;
  background-color: #ffffff;
  height: 550px;
  border-radius: 5px 5px 0px 0px;
  opacity: 1;
}

.bottomBlock {
  border: 1px solid #ffffff !important;
  background-color: #ffffff;
  height: 250px;
  border-radius: 5px 5px 0px 0px;
  opacity: 1;
  margin-bottom: 50px;
}

.botFirst {
  margin-top: 50px;
  margin-left: 80px;
}

.botSecond {
  margin-top: 40px;
  margin-left: 80px;
}

.botThird {
  margin-top: 40px;
  margin-left: 80px;
}

.botTitle {
  font-size: 16px;
  font-weight: 550;
  color: #606266;
}

.botContent {
  font-size: 15px;
  font-weight: 550;
  color: #1890ff;
  margin-left: 90px;
  cursor: pointer;
}

.roundList {
  position: relative;
  top: 18%;
  right: 4%;
}

.roundOne {
  border-radius: 50%;
  border: 1px dashed #c8dbec;
  position: absolute;
  z-index: 1;
}

.btnAll {
  position: relative;
  bottom: 12%;
  left: 42%;
}

.btnAll > .el-button {
  height: 42px;
  display: block;
  margin-top: 15px;
}

.btnText {
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
  padding-bottom: 10px;
}

.thirdBlock > img {
  width: 50%;
  height: 55%;
  position: relative;
  margin-left: 23%;
  margin-top: 5%;
}

.blueFloor {
  width: 60%;
  height: 10%;
  border-radius: 80%;
  background: #2295ff;
  opacity: 0.16;
  position: relative;
  margin-left: 18%;
  margin-top: -10%;
}

.thirdTip {
  text-align: center;
  margin-top: 5%;
}

.thirdTip :nth-child(2) {
  margin-left: 20%;
  margin-right: 20%;
}

.thirdText {
  text-align: center;
  margin-top: 5%;
  margin-left: 3%;
}

.middleText {
  margin-left: 22%;
  margin-right: 22%;
}

.thirdNum {
  margin-left: 1%;
  margin-right: 1%;
}

.blueBlock {
  width: 12px;
  height: 12px;
  background: #2295ff;
  opacity: 1;
  display: inline-block;
  vertical-align: -1px;
}

.textOne {
  color: #909399;
  font-weight: 500;
  margin-left: 30px;
}

.textTwo {
  color: #909399;
  font-size: 18px;
  font-weight: 500;
  position: absolute;
  left: 37%;
  top: 90%;
}

.blueLine {
  display: block;
  margin-top: 10px;
  width: 155px;
  height: 7px;
  background-color: #1890ff;
}

.thatSecond {
  top: 15%;
  left: 8%;
  position: relative;
  text-align: center;
}

.thatSecond1,
.thatSecond2,
.thatSecond3,
.thatSecond4 {
  width: 25%;
  height: 70px;
  float: left;
  border: 1px solid #c8dbec;
}

.that {
  left: 8%;
  position: relative;
  text-align: center;
}

.that1,
.that2,
.that3,
.that4,
.that6,
.that7,
.that8 {
  width: 25%;
  height: 70px;
  float: left;
  border: 1px solid #c8dbec;
}

.that5 {
  width: 25%;
  height: 70px;
  float: left;
  clear: left;
  border: 1px solid #c8dbec;
}

.thatText {
  display: block;
  color: #909399;
  font-size: 15px;
  margin-top: 15px;
}

.thatNum {
  display: block;
  color: #778ca2;
  font-size: 17px;
  margin-top: 5px;
}

.page-container {
  border-left: 0px solid;
  margin-top: 20px;
}

.page-container > span:first-child {
  font-size: 25px;
  position: relative;
  top: 3px;
}

.page-container > button {
  margin-left: 20px;
}

.span_inner {
  color: #909399;
  display: block;
}

.select-content {
  display: flex;
}

.page-content {
  padding: 0 30px 0 20px;
}

.sixthTitle {
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
}

.achie-content {
  margin-top: 20px;
  background-color: #dfe6ec;
  font-size: 17px;
  color: #778ca2;
  height: 30px;
  width: 90%;
  line-height: 30px;
}

.ranking-content {
  text-align: left;
  margin-top: 20px;
  margin-left: 10px;
  height: 10%;
}

.blueCircle {
  width: 35px;
  height: 35px;
  margin-bottom: 5px;
  margin-left: 6%;
  border-radius: 50%;
  background-color: #1890ff;
  display: inline-block;
  vertical-align: middle;
}

.circleFont {
  display: block;
  color: #ffffff;
  font-size: 17px;
  font-weight: 500;
  margin-left: 12px;
  margin-top: 5px;
}

.ranking-content > span {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  color: #909399;
}

.ranking-content :nth-child(2) {
  position: relative;
  width: 100px;
  left: 80px;
}

.ranking-content :nth-child(3) {
  position: relative;
  width: 100px;
  margin-left: 120px;
}

.first {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: red;
  color: #ffffff;
  border-radius: 50px;
  text-align: center;
}

.second {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #f9d261;
  color: #ffffff;
  border-radius: 50px;
  text-align: center;
}

.third {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: green;
  color: #ffffff;
  border-radius: 50px;
  text-align: center;
}

.other {
  display: inline-block;
  width: 20px;
  height: 20px;
  color: #808080;
  border-radius: 50px;
  text-align: center;
}

#pillarChart,
#customSearchContent {
  margin-top: 20px;
  border: 1px solid;
}

#customSearchContent {
  margin-bottom: 20px;
}

.input-inner > label {
  font-size: 16px;
}

.firstBlock {
  .firstTitle {
    margin-top: -30px;
    font-size: 14px;
    color: #909399;
    margin-left: 20px;

    .titleText {
      text-align: center;
      margin-left: 25px;
      margin-right: 25px;
    }
  }
  .firstContent {
    position: absolute;
    // margin-left: 40px;
    // margin-right: 20px;
    .contentText {
      font-size: 19px;
      margin-left: 3px;
    }
    .fixedContent {
      font-size: 16px;
    }
  }
}
#scorePeriodEchartsID {
  width: 620px;
  height: 240px;
  margin: 0 auto;
  margin-top: -40px;
  z-index: 1;
}
.leagueTablesBox {
  width: calc(100% - 10%);
  height: 500px;
  box-sizing: border-box;
  margin-top: -20px;
}
#leagueTablesID {
  width: 100%;
  height: 100%;
}
#stuLeagueTablesID {
  width: 100%;
  height: 100%;
}
#schLeagueTablesID {
  width: 400%;
  height: 500%;
}
</style>
